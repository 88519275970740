import React from "react"
import styled from '@emotion/styled'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'

import AdSenseBlock from '../../Atoms/AdSenseBlock'

const ContentContainer = styled.div`
  height: 100%;
  overflow: auto;
  margin: 5px;
  padding: 5px;
  bacground: white;
`
const max_target_number = 126500000
const config = {
    target_number: {
        name: "target_number",
        description: "対象人数",
        defaultValue: max_target_number,
        max: max_target_number,
        min: 0,
        step: 10000,
        unit: "人"
    },
    prevalence: {
        name: "prevalence",
        description: "有病率",
        defaultValue: 0.044,
        max: 100,
        min: 0,
        step: 0.001,
        unit: "%"
    },
    sensitivity: {
        name: "sensitivity",
        description: "感度",
        defaultValue: 70,
        max: 100,
        min: 0,
        step: 0.1,
        unit: "%"
    },
    specificity: {
        name: "specificity",
        description: "特異度",
        defaultValue: 99,
        max: 100,
        min: 0,
        step: 0.1,
        unit: "%"
    }
}

const ParamGrid = (props) => {
    const { config, value, handleChange } = props
    return (
        <Grid container component={Paper} spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>{config.description}</Grid>
            <Grid item xs={6}>
                <Slider
                    name={config.name}
                    max={config.max}
                    min={config.min}
                    step={config.step}
                    value={value}
                    onChange={(e, newValue) => handleChange(config.name, newValue)}
                    aria-labelledby="continuous-slider"
                />
            </Grid>
            <Grid item xs={3}>{numberFormat(value)}{config.unit}</Grid>
        </Grid>
    )
}
const calculateOutput = (input) => {
    //     positive_person: 0, // 陽性者(対象人数 * 有病率 / 100)
    //     negative_person: 0, // 陰性者(対象人数 * (100 - 有病率) / 100)
    //     true_positive: 0, // 真陽性(陽性者 * 感度 / 100)
    //     false_positive: 0, // 偽陽性(陰性者 * (100 - 特異度) / 100)(第1種の過誤)
    //     false_negative: 0, // 偽陰性(陽性者 * ( 100 - 感度 ) / 100)(第2種の過誤)
    //     true_negative: 0, // 真陰性(陰性者 * 特異度 / 100)
    //     positive_predictive_value: 0, // 陽性的中率: 真陽性数 / 検査陽性数
    //     negative_predictive_value: 0, // 陰性的中率 真陰性数 / 検査陰性数
    //     number_of_positive_tests: 0, // 真陽性数 + 偽陽性
    //     number_of_negative_tests: 0, // 真陰性 + 偽陰性
    //     // 感度: 真陰性数 / (真陽性数 + 偽陰性)
    //     // 特異度: 真陰性数 / (偽陽性 + 真陰性)

    const positive_person = input.target_number * input.prevalence / 100
    const negative_person = input.target_number * (100 - input.prevalence) / 100
    const true_positive = positive_person * input.sensitivity / 100
    const false_positive = negative_person * (100 - input.specificity) / 100
    const false_negative = positive_person * (100 - input.sensitivity) / 100
    const true_negative = negative_person * input.specificity / 100
    const number_of_positive_tests = true_positive + false_positive
    const number_of_negative_tests = true_negative + false_negative
    const positive_predictive_value = true_positive / number_of_positive_tests * 100
    const negative_predictive_value = true_negative / number_of_negative_tests * 100
    var output = {
        positive_person: positive_person,
        negative_person: negative_person,
        true_positive: true_positive,
        false_positive: false_positive,
        false_negative: false_negative,
        true_negative: true_negative,
        number_of_positive_tests: number_of_positive_tests,
        number_of_negative_tests: number_of_negative_tests,
        positive_predictive_value: positive_predictive_value,
        negative_predictive_value: negative_predictive_value,
    }
    return output
}
const numberFormat = (input) => {
    if(input > 100000000) {
        var num_obj = new Number(input / 100000000);
        return num_obj.toFixed(3) + "億"
    } else if(input > 10000) {
        var num_obj = new Number(input / 10000);
        return num_obj.toFixed(3) + "万"
    } else {
        var num_obj = new Number(input);
        return num_obj.toFixed(3)
    }
}
export default class InspectionPerformance extends React.Component {
    constructor(props) {
        super(props);
        const input = {
            target_number: config.target_number.defaultValue,
            prevalence: config.prevalence.defaultValue,
            sensitivity: config.sensitivity.defaultValue,
            specificity: config.specificity.defaultValue,
        }
        const output = calculateOutput(input)
        this.state = {
            input: input,
            output: output
        };

    }

    handleChange = (name, newValue) => {
        var state = {
            ...this.state,
            input: {
                ...this.state.input,
                [name]: newValue
            }
        }
        const output = calculateOutput(state.input)
        this.setState({
            ...state,
            output: output
        })
    }

    render() {
        const { input, output } = this.state
        const { target_number, prevalence, sensitivity, specificity } = input
        const {
            positive_person,
            negative_person,
            true_positive,
            false_positive,
            true_negative,
            false_negative,
            number_of_positive_tests,
            number_of_negative_tests,
            positive_predictive_value,
            negative_predictive_value
        } = output
        return (
            <ContentContainer className="App-header">
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} align="left">
                        <h1>検査について調べました</h1>
                        検査には限界があり、どうしても陽性を陰性、陰性を陽性と判断する検査結果が出てしまいます。<br/>
                        実際に値を変えて確かめてみました。
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={6} md={6}><AdSenseBlock id={1} /></Grid>
                    <Grid item xs={6} md={6}><AdSenseBlock id={2} /></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} align="left">
                        <h2>検査条件</h2>
                        条件を設定してください。
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
                <ParamGrid config={config.target_number} value={target_number} handleChange={this.handleChange} />
                <ParamGrid config={config.prevalence} value={prevalence} handleChange={this.handleChange} />
                <ParamGrid config={config.sensitivity} value={sensitivity} handleChange={this.handleChange} />
                <ParamGrid config={config.specificity} value={specificity} handleChange={this.handleChange} />
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right">真の状態:陽性</TableCell>
                                        <TableCell align="right">真の状態:陰性</TableCell>
                                        <TableCell align="right">的中率</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow >
                                        <TableCell component="th" scope="row"><Typography noWrap>検査結果:陽性</Typography></TableCell>
                                        <TableCell align="left"><Typography noWrap>真陽性: {numberFormat(true_positive)}人</Typography></TableCell>
                                        <TableCell align="left"><Typography noWrap>偽陽性: {numberFormat(false_positive)}人</Typography></TableCell>
                                        <TableCell align="right"><Typography noWrap>真陽性的中率: {numberFormat(positive_predictive_value)}%</Typography></TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell component="th" scope="row"><Typography noWrap>検査結果:陰性</Typography></TableCell>
                                        <TableCell align="left"><Typography noWrap>偽陰性: {numberFormat(false_negative)}人</Typography></TableCell>
                                        <TableCell align="left"><Typography noWrap>真陰性: {numberFormat(true_negative)}人</Typography></TableCell>
                                        <TableCell align="right"><Typography noWrap>真陰性的中率: {numberFormat(negative_predictive_value)}%</Typography></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} align="left">
                        <h2>検査前提条件</h2>
                        <div>
                            {numberFormat(target_number)}人に対して検査を行うことにします。<br/>
                            この対象の中に、{numberFormat(prevalence)}%の陽性者がいるとします。<br/>
                            すると、陽性者は{numberFormat(positive_person)}人・陰性者は{numberFormat(negative_person)}人いることになります。<br/>
                            また、検査の感度を{numberFormat(sensitivity)}%、特異度を{numberFormat(specificity)}%とします。
                        </div>
                        <h2>検査結果</h2>
                        <div>
                            上記の検査の結果は下記のようになります。<br/>
                            <ul>
                                <li>陽性者:{numberFormat(positive_person)}人のうち、{numberFormat(true_positive)}人は正しく陽性となり、{numberFormat(false_negative)}人は誤って陰性となります。</li>
                                <li>陰性者:{numberFormat(negative_person)}人のうち、{numberFormat(true_negative)}人は正しく陰性となり、{numberFormat(false_positive)}人は誤って陽性となります。</li>
                                <li>陽性的中率は、{numberFormat(positive_predictive_value)}%</li>
                                <li>陰性的中率は、{numberFormat(negative_predictive_value)}%</li>
                                <li>検査の結果、{numberFormat(number_of_positive_tests)}が陽性と判定され、{numberFormat(number_of_negative_tests)}が陰性と判定されます。</li>
                            </ul>
                        </div>
                        <h2>検査結果から考えられること</h2>
                        <div>
                            <ul>
                                <li>{numberFormat(false_negative)}人の偽陰性者が、見逃されて市中感染の原因となります。</li>
                                <li>{numberFormat(false_positive)}人の偽陽性者が、医療リソースを圧迫し医療崩壊の原因となります。</li>
                            </ul>
                            また、本来は{numberFormat(positive_person)}人であるはずの陽性者が{numberFormat(number_of_positive_tests / positive_person)}倍の{numberFormat(number_of_positive_tests)}人が検査陽性者として判断されます。<br/>
                            仮にあなたが検査の結果、陰性と診断された時に「私は陰性と診断されたんだ！」と「外に出てもいいんだ、普通に暮らしてもいいんだ」と思ったりしませんか？
                            {/* 無作為に大量の検査を行うことは、上記の問題をはらんでいます。<br/>
                            この問題を解決するために、検査対象にするかどうかの基準を設定し、「検査対象を減らすこと」と「検査対象内の有病率を上げること」必要があります。<br/>
                            だからこそ、検査対象の基準が設けられています。
                            <ul>
                                <li>風邪の症状や３７．５度以上の熱が４日以上続いていること(解熱剤を飲み続けなければならない方も含みます)</li>
                                <li>倦怠感や息苦しさ呼吸困難があること(※高齢者や糖尿病、心不全、呼吸疾患などの基礎疾患のある方や透析、免疫抑制剤や抗がん剤等を服用されている方は上記症状が２日以上続く場合)</li>
                                <li>陽性感染者の濃厚接触者であること</li>
                            </ul> */}
                        </div>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} align="left">
                        <h2>計算方法</h2>
                        計算式の参考<br/>
                        <a href="https://ja.wikipedia.org/wiki/%E6%84%9F%E5%BA%A6" target="_brank">Wiki:感度</a>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} align="left">陽性者</Grid>
                    <Grid item xs={6} align="left">対象人数 * 有病率 / 100</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} align="left">陰性者</Grid>
                    <Grid item xs={6} align="left">対象人数 * (100 - 有病率) / 100</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} align="left">真陽性</Grid>
                    <Grid item xs={6} align="left">陽性者 * 感度 / 100</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} align="left">偽陰性</Grid>
                    <Grid item xs={6} align="left">陽性者 * ( 100 - 感度 ) / 100</Grid>
                    <Grid item xs={2}>第2種の過誤</Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} align="left">偽陽性</Grid>
                    <Grid item xs={6} align="left">陰性者 * ( 100 - 特異度 ) / 100</Grid>
                    <Grid item xs={2}>第1種の過誤</Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} align="left">真陰性</Grid>
                    <Grid item xs={6} align="left">陰性者 * 特異度 / 100</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} align="left">陽性的中率</Grid>
                    <Grid item xs={6} align="left">真陽性数 / 検査陽性数</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} align="left">陰性的中率</Grid>
                    <Grid item xs={6} align="left">真陰性数 / 検査陰性数</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} align="left">検査陽性数</Grid>
                    <Grid item xs={6} align="left">真陽性数 + 偽陽性数</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} align="left">検査陰性数</Grid>
                    <Grid item xs={6} align="left">真陰性数 + 偽陰性数</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} align="left">感度</Grid>
                    <Grid item xs={6} align="left">真陰性数 / (真陽性数 + 偽陰性)</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container component={Paper} spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} align="left">特異度</Grid>
                    <Grid item xs={6} align="left">真陰性数 / (偽陽性 + 真陰性)</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Grid container spacing={1} p={1} m={5}>
                    <Grid item xs={6} md={6}><AdSenseBlock id={1} /></Grid>
                    <Grid item xs={6} md={6}><AdSenseBlock id={2} /></Grid>
                </Grid>
            </ContentContainer>
        );
    }
}