import AdSense from 'react-adsense';
import React from 'react';
const adClient = process.env.AD_CLIENT
const assets = {
    1 : {
        slot: process.env.AD_SLOT_TOP_RIGHT
    },
    2 : {
        slot: process.env.AD_SLOT_TOP_LEFT
    },
    3 : {
        slot: process.env.AD_SLOT_BOTTOM_RIGHT
    },
    4 : {
        slot: process.env.AD_SLOT_BOTTOM_LEFT
    },

}
const AdSenseBlock = ({id}) => {
    const asset = assets[id]
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        return (<div>AdSenseを表示</div>)
    } else {
        return (
            <div className={{height:'100%'}}>
                <AdSense.Google
                  client={adClient}
                  slot={asset.slot} />
            </div>
        )
    }
}
export default AdSenseBlock

// import React, { useState, useEffect } from 'react'
// import styled from '@emotion/styled'
// import responsive from 'responsive'

// const AdSense = ({ format = "auto", slot }) => {
//     const adClient = process.env.AD_CLIENT
//     const slots = {
//         1: process.env.AD_SLOT_RIGHT,
//         2: process.env.AD_SLOT_LEFT
//     }
//     console.log("you adClient", adClient);
//     console.log("you slots", slots);

//     const [state, setState] = useState({ showAds: false })

//     useEffect(() => {
//         if (state.showAds) {
//             window.adsbygoogle = window.adsbygoogle || []
//             window.adsbygoogle.push({})
//         }
//         if (window) {
//             const minWidth = responsive.tablet.minWidth
//             const shouldShowAds = window.innerWidth >= minWidth
//             if (shouldShowAds) {
//                 setState({ showAds: true })
//             }
//         }
//     }, [state.showAds])

//     if (!state.showAds) return null

//     return (
//         <div>
//             <Ins
//                 className="adsbygoogle"
//                 data-ad-client={adClient}
//                 data-ad-slot={slots[slot]}
//                 data-ad-format={format}
//             />
//         </div>
//     )
// }
// const Ins = styled.ins({
//     display: "block"
// })

// export default AdSense