import React from "react"
import styled from '@emotion/styled'
import MUIDataTable from "mui-datatables";


const url = "http://localhost/public/api/not-be-forgotten/covid19"
const columns = [
    {
        name: "no",
        label: "No",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "prefecture.name",
        label: "都道府県",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "municipality",
        label: "都市",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "age.name",
        label: "年齢",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "gender.name",
        label: "性別",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "status.name",
        label: "状態",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "profession",
        label: "職業",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "nationality",
        label: "国籍",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "confirmation_at",
        label: "感染確認日",
        options: {
            filter: false,
            sort: true,
        }
    },
];

const options = {
    filterType: 'checkbox',
    rowsPerPage: 300,
    rowsPerPageOptions: [100, 200, 300, 500, 1000]
};

const ContentContainer = styled.div`
  height: 100%;
  overflow: auto;
  margin: 5px;
  padding: 5px;
`

export default class InfectedPersons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
            this.setState({
                loading: true,
                data: responseJson,
            });
        }).catch((error) => {
            console.error(error);
        });
    }

    render() {
        const { loading, data } = this.state
        if (loading) {
            return (
                <ContentContainer className="App-header">
                    <MUIDataTable
                        title={"感染者"}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </ContentContainer>
            );
        } else {
            return (
                <div className="App-header">
                    <p>Loading...</p>
                </div>
            );
        }
    }
}