import React from 'react'
import { Router } from "@reach/router"
import Layout from '../Templates/Layout'
import InfectedPersons from './Covid19/InfectedPersons'
import AddInfectedPersons from './Covid19/AddInfectedPersons'

import InspectionPerformance from './Covid19/InspectionPerformance'
import PosterGenerator from './Covid19/PosterGenerator'

const App = () => (
  <Layout>
    <Router>
      <InspectionPerformance path="/app/inspection_performance" />
      <InfectedPersons path="/app/infected_persons" />
      <AddInfectedPersons path="/app/infected_persons/add" />

      <PosterGenerator path="/app/covid19/poster-generator" />
    </Router>
  </Layout>
)
export default App
