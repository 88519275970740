import React from "react"
import styled from '@emotion/styled'

import guideline from '../../../assets/covid19/guideline.json'
import guideline_svg from '../../../assets/covid19/guideline.svg'

import MUIDataTable from "mui-datatables";

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import AdSenseBlock from '../../Atoms/AdSenseBlock'

const guideHash = guideline.reduce((a, c) => {
    c.checked.forEach((row, index) => {
        a[row.id] = row
    })
    return a
}, {})

const ContentContainer = styled.div`
  height: 100%;
  overflow: auto;
  margin: 5px;
  padding: 5px;
  bacground: white;
`

const columns = [
    {
        name: "id",
        label: "ID",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "title",
        label: "title",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "description",
        label: "description",
        options: {
            filter: true,
            sort: true,
        }
    },
];

const options = {
    filterType: 'checkbox',
    rowsPerPage: 300,
    rowsPerPageOptions: [100, 200, 300, 500, 1000]
};

export default class PosterGenerator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: {}
        };
    }

    handleChange = (guideId, checkedId, checked) => {
        const { selected } = this.state
        const origin = selected[guideId]
        var newSelected = []
        if(origin !== undefined) {
            newSelected = [].concat(origin)
        }
        if(checked) {
            newSelected.push(`${guideId}_${checkedId}`)
        } else {
            newSelected = newSelected.filter((v) => {
                return checkedId !== v
            })
        }
        newSelected.sort()
        this.setState({selected: {
            ...selected,
            [guideId]: newSelected
        }})
    }

    render() {
        const { selected } = this.state
        return (
            <ContentContainer className="App-header">
                <Grid container component={Paper} spacing={2} align="left">
                    <Grid item xs={12}>
                        <h1>COVID-19(新型コロナウイルス感染症)の対策について調べました</h1>
                        <p>
                            COVID-19(新型コロナウイルス感染症)で対策について調べると、「これをしないといけない」、「あれをしてはいけない」といった記載が見つかる。<br />
                            しかし、「誰から誰への感染を防ぐのか？」や「なぜ感染するのか？」がわからない情報が見つかる。<br />
                            そこで、「誰から誰への感染を防ぐのか？」・「なぜ感染するのか？」について、まとめていきます。<br />
                        </p>
                        <h2>感染経路について考える</h2>
                        <p>
                            まず、当たり前のことながら、COVID-19(新型コロナウイルス感染症)は感染症なのであり、人から人へ感染が広がります。<br />
                            具体的に言うと「COVID-19(新型コロナウイルス感染症)に感染している人」から「COVID-19(新型コロナウイルス感染症)に感染していない人」へ「COVID-19(新型コロナウイルス感染症)が何らかの経路で移動すること」が感染症を広めます。<br />
                        </p>
                        <img src={guideline_svg} alt={"COVID-19の感染経路図"} />
                        <h3>「COVID-19(新型コロナウイルス感染症)に感染している人」・「COVID-19(新型コロナウイルス感染症)に感染していない人」とは誰か？</h3>
                        <p>
                            「COVID-19(新型コロナウイルス感染症)に感染している人」・「COVID-19(新型コロナウイルス感染症)に感染していない人」は「利用者」・「従業員」です。<br />
                            しかし、「利用者」・「従業員」のどちらが感染していて、感染していないかはわかりません。
                            そのため、下記の3つのパターンが考えられます。<br />
                            <ul>
                                <li>利用者から利用者への感染</li>
                                <li>利用者から従業員への感染(従業員から利用者への感染)</li>
                                <li>従業員から従業員への感染</li>
                            </ul>
                            特に、私は「利用者から利用者への感染」については「一緒にきた同一グループの利用者内の感染」よりも「同一時間帯に利用した別グループの利用者間の感染」を防ぐことに主眼を置いた方がいいと考えています。
                        </p>
                        <h3>「COVID-19(新型コロナウイルス感染症)が何らかの経路で移動すること」とは何か？</h3>
                        <p>
                            COVID-19(新型コロナウイルス感染症)の感染経路は下記の2つのパターンが報告されています。<br />
                            <ul>
                                <li>飛沫感染:感染者の飛沫（くしゃみ、咳、つばなど）と一緒にウイルスが放出され、他の方がそのウイルスを口や鼻などから吸い込んで感染すること</li>
                                <li>接触感染:感染者がくしゃみや咳を手で押さえた後、周りのモノに触り、ウイルスがモノにつきます。他の人がそのモノを触るとウイルスが手に付着し、その手で口や鼻を触ることにより粘膜から感染すること。(*WHOは、新型コロナウイルスは、プラスチックの表面では最大72時間、ボール紙では最大24時間生存するなどとしています。)</li>
                            </ul>
                            つまり、「利用者」・「従業員」がそれぞれが「共有・共用するモノ」の存在が感染の原因になります。<br />
                        </p>
                        <h2>感染経路についてまとめる</h2>
                        <p>
                            「COVID-19(新型コロナウイルス感染症)に感染している人」から「COVID-19(新型コロナウイルス感染症)に感染していない人」へ「COVID-19(新型コロナウイルス感染症)が何らかの経路で移動すること」を具体的にまとめると下記のようになります。<br />
                            <ul>
                                <li>利用者から利用者への飛沫感染</li>
                                <li>利用者から利用者への接触感染</li>
                                <li>利用者から従業員への飛沫感染(従業員から利用者への飛沫感染)</li>
                                <li>利用者から従業員への接触感染(従業員から利用者への接触感染)</li>
                                <li>従業員から従業員への飛沫感染</li>
                                <li>従業員から従業員への接触感染</li>
                            </ul>
                            上記の感染パターンをどうやって防ぐかのチェックリストを作っていきます。
                        </p>
                    </Grid>
                    <Grid container item xs={12} align="left">
                        <Grid item xs={12} align="left">
                            {
                                guideline.map((guide, index) => {
                                    return (
                                        <div key={`guide_${guide.id}`}>
                                            <h3>{guide.title}</h3>
                                            {
                                                guide.checked.map(c => {
                                                    return (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={selected[guide.id] && selected[guide.id].includes(c.id)}
                                                                    onChange={(e, checked) => this.handleChange(c.guideId, c.checkedId, checked)}
                                                                    name={c.id}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={c.name}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item xs={12} align="left">
                            {
                                Object.keys(selected).map((guideId) => {
                                    return (
                                        <div key={`output_guide_${guideId}`}>
                                            <h3>aa</h3>
                                            {
                                                <ul>
                                                    {
                                                        selected[guideId].map((key) => {
                                                            return (
                                                                <li key={`output_checklist_${key}`}>
                                                                    {guideHash[key].name}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            }
                                        </div>
                                    )


                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={6} md={6}><AdSenseBlock id={1} /></Grid>
                    <Grid item xs={6} md={6}><AdSenseBlock id={2} /></Grid>
                </Grid>
            </ContentContainer>
        );
    }
}