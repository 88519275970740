import React from "react"
import styled from '@emotion/styled'
import dateformat from 'dateformat'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';

const url = "http://localhost/public/api/not-be-forgotten/covid19/infected-persons"

const infected_person_options = {
    prefectures: [
        { id: 1, name: "北海道", information_source: "http://www.pref.hokkaido.lg.jp/hf/kth/kak/hasseijoukyou.htm" },
        { id: 2, name: "青森県", information_source: "https://www.pref.aomori.lg.jp/welfare/health/wuhan-novel-coronavirus2020.html" },
        { id: 3, name: "岩手県", information_source: "https://www.pref.iwate.jp/kurashikankyou/iryou/kenkou/jouhou/1028020.html" },
        { id: 4, name: "宮城県", information_source: "https://www.pref.miyagi.jp/site/covid-19/02.html" },
        { id: 5, name: "秋田県", information_source: "https://www.pref.akita.lg.jp/pages/archive/47957#houdouhappyou" },
        { id: 6, name: "山形県", information_source: "https://www.pref.yamagata.jp/ou/bosai/020072/kochibou/coronavirus/coronavirus.html" },
        { id: 7, name: "福島県", information_source: "https://www.pref.fukushima.lg.jp/sec/21045c/fukushima-hasseijyoukyou.html" },
        { id: 8, name: "茨城県", information_source: "https://www.pref.ibaraki.jp/1saigai/2019-ncov/index.html" },
        { id: 9, name: "栃木県", information_source: "http://www.pref.tochigi.lg.jp/e04/welfare/hoken-eisei/kansen/hp/coronakensahasseijyoukyou.html" },
        { id: 10, name: "群馬県", information_source: "https://www.pref.gunma.jp/07/z87g_00016.html" },
        { id: 11, name: "埼玉県", information_source: "https://www.pref.saitama.lg.jp/a0701/covid19/jokyo.html" },
        { id: 12, name: "千葉県", information_source: "https://www.pref.chiba.lg.jp/shippei/press/2019/ncov-index.html" },
        { id: 13, name: "東京都", information_source: "https://stopcovid19.metro.tokyo.lg.jp/data/130001_tokyo_covid19_patients.csv" },
        { id: 14, name: "神奈川県", information_source: "https://www.pref.kanagawa.jp/docs/ga4/bukanshi/occurrence.html" },
        { id: 15, name: "新潟県", information_source: "https://www.pref.niigata.lg.jp/sec/kikitaisaku/shingata-corona.html#hasseijoukyou" },
        { id: 16, name: "富山県", information_source: "http://www.pref.toyama.jp/" },
        { id: 17, name: "石川県", information_source: "https://www.pref.ishikawa.lg.jp/kansen/coronakennai.html" },
        { id: 18, name: "福井県", information_source: "https://www.pref.fukui.lg.jp/doc/kenkou/kansensyo-yobousessyu/corona.html" },
        { id: 19, name: "山梨県", information_source: "https://www.pref.yamanashi.jp/koucho/coronavirus/info_coronavirus.html" },
        { id: 20, name: "長野県", information_source: "https://www.pref.nagano.lg.jp/hoken-shippei/kenko/kenko/kansensho/joho/bukan-haien-doko.html" },
        { id: 21, name: "岐阜県", information_source: "https://www.pref.gifu.lg.jp/kinkyu-juyo-joho/shingata_corona.html" },
        { id: 22, name: "静岡県", information_source: "http://www.pref.shizuoka.jp/kinkyu/covid-19.html" },
        { id: 23, name: "愛知県", information_source: "https://www.pref.aichi.jp/site/covid19-aichi/" },
        { id: 24, name: "三重県", information_source: "https://www.pref.mie.lg.jp/YAKUMUS/HP/m0068000066.htm" },
        { id: 25, name: "滋賀県", information_source: "https://www.pref.shiga.lg.jp/ippan/kenkouiryouhukushi/yakuzi/310735.html" },
        { id: 26, name: "京都府", information_source: "https://www.pref.kyoto.jp/kentai/news/novelcoronavirus.html#F" },
        { id: 27, name: "大阪府", information_source: "https://covid19-osaka.info/" },
        { id: 28, name: "兵庫県", information_source: "https://web.pref.hyogo.lg.jp/kk03/corona_hasseijyokyo.html" },
        { id: 29, name: "奈良県", information_source: "http://www.pref.nara.jp/54373.htm" },
        { id: 30, name: "和歌山県", information_source: "https://www.pref.wakayama.lg.jp/prefg/041200/d00203387.html" },
        { id: 31, name: "鳥取県", information_source: "https://www.pref.tottori.lg.jp/289682.htm" },
        { id: 32, name: "島根県", information_source: "https://www.pref.shimane.lg.jp/bousai_info/bousai/kikikanri/kikikanri_jian/newcoronavirus.html" },
        { id: 33, name: "岡山県", information_source: "https://www.pref.okayama.jp/page/645925.html#kennaijoukyou" },
        { id: 34, name: "広島県", information_source: "https://www.pref.hiroshima.lg.jp/soshiki/57/bukan-coronavirus.html" },
        { id: 35, name: "山口県", information_source: "https://www.pref.yamaguchi.lg.jp/cms/a15200/kansensyou/koronahassei.html" },
        { id: 36, name: "徳島県", information_source: "https://www.pref.tokushima.lg.jp/ippannokata/kenko/kansensho/5034012#20" },
        { id: 37, name: "香川県", information_source: "https://www.pref.kagawa.lg.jp/content/dir1/dir1_6/dir1_6_2/wt5q49200131182439.shtml#outbreak" },
        { id: 38, name: "愛媛県", information_source: "https://www.pref.ehime.jp/h25500/kansen/covid19.html" },
        { id: 39, name: "高知県", information_source: "https://www.pref.kochi.lg.jp/soshiki/130401/2020022900049.html" },
        { id: 40, name: "福岡県", information_source: "https://www.pref.fukuoka.lg.jp/contents/covid19-hassei.html" },
        { id: 41, name: "佐賀県", information_source: "https://www.pref.saga.lg.jp/kiji00373220/index.html" },
        { id: 42, name: "長崎県", information_source: "https://www.pref.nagasaki.jp/bunrui/hukushi-hoken/kansensho/corona_nagasaki/corona_nagasaki_shousai/" },
        { id: 43, name: "熊本県", information_source: "https://www.pref.kumamoto.jp/kiji_30386.html#kansensya" },
        { id: 44, name: "大分県", information_source: "https://www.pref.oita.jp/site/bosaianzen/shingatacorona.html" },
        { id: 45, name: "宮崎県", information_source: "https://www.pref.miyazaki.lg.jp/kenko/hoken/kansensho/covid19/hassei.html" },
        { id: 46, name: "鹿児島県", information_source: "https://www.pref.kagoshima.jp/ae06/kenko-fukushi/kenko-iryo/kansen/kansensho/coronavirus.html" },
        { id: 47, name: "沖縄県", information_source: "https://www.pref.okinawa.jp/site/hoken/chiikihoken/kekkaku/press/20200214_covid19_pr1.html" },
    ],
    age: [
        { id: 1, name: "10歳代" },
        { id: 2, name: "20歳代" },
        { id: 3, name: "30歳代" },
        { id: 4, name: "40歳代" },
        { id: 5, name: "50歳代" },
        { id: 6, name: "60歳代" },
        { id: 7, name: "70歳代" },
        { id: 8, name: "80歳代" },
        { id: 9, name: "90歳代" },
        { id: 10, name: "100歳代" },
        { id: 11, name: "10歳未満" },
        { id: 12, name: "高齢者" },
        { id: 13, name: "非公開" },
        { id: 14, name: "不明" },
    ],
    gender: [
        { id: 1, name: "女性" },
        { id: 2, name: "男性" },
        { id: 3, name: "非公開" },
        { id: 4, name: "不明" },
    ],
    status: [
        { id: 1, name: "復帰" },
        { id: 2, name: "死亡" },
        { id: 3, name: "感染" },
        { id: 4, name: "入院" },
        { id: 5, name: "退院" },
        { id: 6, name: "不明" },
    ]
}

const ContentContainer = styled.div`
  height: 100%;
  overflow: auto;
  margin: 5px;
  padding: 5px;
`

const ButtonsContainer = styled.div`
  height: 100%;
  overflow: auto;
  margin: 5px;
  padding: 5px;
  text-align:right;
`

const postData = (url = ``, data = {}) => {
    var information_source = infected_person_options.prefectures.find(element => element.id == data.pref_code)
    information_source = information_source ? information_source.information_source : ""
    var date = dateformat(new Date(), 'yyyy-mm-dd');
    var body = new FormData()
    body.append("no", data.no)
    body.append("status_id", data.status_id)
    body.append("pref_code", data.pref_code)
    body.append("municipality", data.municipality)
    body.append("age", data.age)
    body.append("gender", data.gender)
    body.append("residence", data.residence || "")
    body.append("profession", data.profession)
    body.append("nationality", data.nationality)
    body.append("information_source", information_source)
    body.append("confirmation_at", data.confirmation_at)
    body.append("created_at", date)
    body.append("updated_at", date)
    if(data.start_at !== "") {
        body.append("start_at", data.start_at)
    }
    if(data.end_at !== "") {
        body.append("end_at", data.end_at)
    }
    if(data.dead_at !== "") {
        body.append("dead_at", data.dead_at)
    }
    const method = "POST";
    const headers = {
      'Accept': 'application/json'
    };

    return fetch(url, {method, headers, body}).then(response => response.json());
}
export default class AddInfectedPersons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            openAddInfectedPersonModal: false,
            infected_person: {
                no: "",
                status_id: 4,
                pref_code: "",
                municipality: "",
                age: "",
                gender: "",
                residence: "",
                profession: "",
                nationality: "",
                information_source: "",
                start_at: "",
                confirmation_at: "",
                end_at: "",
                dead_at: "",
            },
            message: "",
            errors: {}
        };
    }

    handleInsert = () => {
        const { infected_person } = this.state
        const data = {
            ...infected_person,
        }
        postData(url, data).then(data => {
            this.setState({
                message: data.message,
                errors: data.errors
            })
            console.error(data.message)
            console.error(data.errors)
        }).catch(error => {
            this.setState({
                message: error.message,
                errors: error.errors
            })
            console.error(error.message)
            console.error(error.errors)
        });
    };

    handleChange = (e, name) => {
        var name = e.target.name || name;
        var value = ["status_id", "pref_code", "age", "gender"].includes(name) ? Number(e.target.value) : e.target.value;
        const { infected_person } = this.state
        this.setState({
            infected_person: {
                ...infected_person,
                [name]: value
            }
        })
    }

    InfectedPersonTextField = (props) => {
        return (
            <TextField
                style={{ margin: "3px" }}
                autoFocus
                fullWidth
                margin="dense"
                {...props}
                onChange={(e) => props.onChange(e, props.name)}
            />

        )
    }

    InfectedPersonRadio = (props) => {
        return (
            <FormControl component="fieldset" fullWidth style={{ margin: "3px" }}>
                <FormLabel component="legend">{props.label}</FormLabel>
                <RadioGroup row aria-label="position" name={props.name} value={props.value} onChange={this.handleChange}>
                    {
                        props.data.map((item) => {
                            return <FormControlLabel key={props.name + "_" + item.id} value={item.id} control={<Radio color="primary" />} label={item.name} />
                        })
                    }
                </RadioGroup>
            </FormControl>
        )
    }

    InfectedPersonSelect = (props) => {
        return (
            <FormControl component="fieldset" fullWidth style={{ margin: "3px" }}>
                <FormLabel id="demo-simple-select-label">{props.label}</FormLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name={props.name}
                    value={props.value}
                    onChange={this.handleChange} >
                    {
                        props.data.map((item) => {
                            return <MenuItem key={props.name + "_" + item.id} value={item.id}>{item.name}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        )
    }

    InfectedPersonAddDialog = (props) => {
        const { data } = props
        const { no, status_id, pref_code, municipality, age, gender, residence, profession, nationality, start_at, confirmation_at, end_at, dead_at } = data
        return (
            <Paper>
                <this.InfectedPersonTextField
                    multiline
                    rows={5}
                    type="date"
                    name="residence"
                    label="メモ"
                    value={residence}
                    onChange={this.handleChange}
                />
                <this.InfectedPersonRadio
                    label="性別"
                    name="gender"
                    value={gender}
                    data={infected_person_options.gender}
                />
                <this.InfectedPersonRadio
                    label="感染者状態"
                    name="status_id"
                    value={status_id}
                    data={infected_person_options.status}
                />
                <this.InfectedPersonSelect
                    label="都道府県"
                    name="pref_code"
                    value={pref_code}
                    data={infected_person_options.prefectures}
                />
                <this.InfectedPersonSelect
                    label="年代"
                    name="age"
                    value={age}
                    data={infected_person_options.age}
                />
                <this.InfectedPersonTextField
                    type="number"
                    name="no"
                    label="No"
                    value={no}
                    onChange={this.handleChange}
                />
                <this.InfectedPersonTextField
                    type="text"
                    name="nationality"
                    label="国籍"
                    value={nationality}
                    onChange={this.handleChange}
                />
                <this.InfectedPersonTextField
                    type="text"
                    name="municipality"
                    label="都市"
                    value={municipality}
                    onChange={this.handleChange}
                />
                <this.InfectedPersonTextField
                    type="text"
                    name="profession"
                    label="職業"
                    value={profession}
                    onChange={this.handleChange}
                />
                <this.InfectedPersonTextField
                    type="date"
                    name="confirmation_at"
                    label="感染確認日"
                    value={confirmation_at}
                    onChange={this.handleChange}
                />
                <this.InfectedPersonTextField
                    type="date"
                    name="start_at"
                    label="発症開始日"
                    value={start_at}
                    onChange={this.handleChange}
                />
                <this.InfectedPersonTextField
                    type="date"
                    name="end_at"
                    label="発症終了日"
                    value={end_at}
                    onChange={this.handleChange}
                />
                <this.InfectedPersonTextField
                    type="date"
                    name="dead_at"
                    label="死亡日"
                    value={dead_at}
                    onChange={this.handleChange}
                />
            </Paper>
        )
    }

    render() {
        const { openAddInfectedPersonModal, infected_person } = this.state
        return (
            <ContentContainer className="App-header">
                <ButtonsContainer>
                    <Button variant="contained" onClick={this.handleInsert}>登録</Button>
                </ButtonsContainer>

                <Paper style={{padding:"5px", margin:"5px"}}>
                    {this.state.message}
                    {
                        Object.keys(this.state.errors).map((key) => {
                            return (
                                <div>
                                    {key} : {this.state.errors[key]}
                                </div>
                            )
                        })
                    }
                </Paper>
                <this.InfectedPersonAddDialog
                    open={openAddInfectedPersonModal}
                    data={infected_person}
                />
            </ContentContainer>
        );
    }
}